import * as React from 'react';
import { Button, Navbar, Nav } from 'react-bootstrap';
import {Amplify, Auth } from 'aws-amplify';
import awsconfig from '../amplifyConfig';

Amplify.configure(awsconfig);

class AppNavBar extends React.Component {
    render() {
      return (
        <Navbar bg="dark" variant="dark">
          <Navbar.Brand>多要素認証(MFA)設定</Navbar.Brand>
          <Nav className="mr-auto">
            {/* <Nav.Link href="/profile" >プロフィール</Nav.Link> */}
            {/* <Nav.Link href="/" >MFA設定</Nav.Link> */}
            {/* <Nav.Link href="/passwd" >パスワード設定</Nav.Link> */}
          </Nav>
          <Button variant="secondary" onClick={() => Auth.signOut({ global : true })}>ログアウト</Button>
        </Navbar>
      );
    }
  }
  
  export default AppNavBar;