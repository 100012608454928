import * as React from 'react';
import { Card, Button, Spinner, Fade, Form, Container, Row, Col, Alert, Figure } from 'react-bootstrap';
import QRCode from "qrcode.react";
import { Amplify, API, Auth } from 'aws-amplify';
import awsconfig from '../amplifyConfig';

Amplify.configure(awsconfig);

class MfaSetting extends React.Component {
  constructor() {
    super();
    this.state = {
      TOTPopen: false,
      SMSopen: false,
      SMScode: false,
      messageShow: false,
      AuthCodeOpen: false,
      AuthCode: '',
      TelNumber: '',
      showSecretkey: false,
      isProcess: false,
      isProcessSuccess: false,
      secretCode: 'xxxxxxxxxx',
      userSession: '',
      accessToken: '',
      idToken: '',
      sendUrl: '',
      selectedOption: '',
      username: '',
      name: '',
      email: '',
    };
    this.onValueChange = this.onValueChange.bind(this);
    this.formSubmit = this.formSubmit.bind(this);
    this.formBack = this.formBack.bind(this);
    this.handleMessageClose = this.handleMessageClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.telnumberChange = this.telnumberChange.bind(this);
    this.onClickSendCode = this.onClickSendCode.bind(this);
    this.onClickSendCodeBack = this.onClickSendCodeBack.bind(this);
    this.onClickReSendCode = this.onClickReSendCode.bind(this);
    this.onShowHideSecretKey = this.onShowHideSecretKey.bind(this);
    // this.testHandleUpdate = this.testHandleUpdate.bind(this);
    //this.testUpdate = this.testUpdate.bind(this);
    this.getSession();
  }

  onValueChange(event) {
    this.setState({
      selectedOption: event.target.value
    });
  }

  formBack() {
    this.setState({ isProcess : true });
    this.setState({ 
      TOTPopen: false,
      SMSopen: false,
      SMScode: false,
      messageShow: false,
      AuthCode: '',
      showSecretkey: false,
      isProcess: false,
      isProcessSuccess: false,
    });
    console.log(this.state);
    this.setState({ isProcess: false });
  }

  /**
   * セッション取得
   */
  async getSession(){

    const session = await Auth.currentSession();
    this.setState({ 
      userSession: session,
      accessToken: session.accessToken.jwtToken,
      idToken: session.idToken.jwtToken,
    });

    const path = '/user/get';
    const params = {
      accessToken: session.accessToken.jwtToken,
      user_pool: awsconfig.Auth.userPoolId,
    };

    const response = await postData(path,params,session.idToken.jwtToken);

    this.setState({ username: response['custom:dispName']});
    this.setState({ name: response['name']});
    this.setState({ email: response['email']});

    const phoneNumber = response['phone_number'];
    phoneNumber != null ? this.setState({ SMScode: true }) : this.setState({ SMScode: false });
  }

  /**
   * MFA設定
   */
  async formSubmit() {
    this.setState({ isProcess : true });

    const Url = localStorage.getItem("uri"); 
    this.setState({ sendUrl: Url });

    const path = '/mfa/assignAuthCode';
    const params = {
      accessToken: this.state.accessToken,
      status: this.state.selectedOption,
    };

    this.state.selectedOption === 'SMS' ?
    this.setState({ 
      SMSopen : true
    })
    : this.state.selectedOption === 'TOTP' ? 
    this.setState({ 
      TOTPopen : true
    })
    : this.setState({ 
      SMSopen : false,
      SMScode: false,
      TOTPopen : false
    }); 
    
    // if(this.state.selectedOption === 'TOTP'){
      try{
        const secretCode = await postData(path,params,this.state.idToken);
        this.setState({ secretCode: secretCode });
      }catch(e){
        console.log(e);
      }
    // };

    console.log(this.state);
    this.setState({ isProcess: false });
  }

  async handleChange(event){
    this.setState({ AuthCode: event.target.value});
  }

  async telnumberChange(event){
    this.setState({ TelNumber: event.target.value});
  }

  //電話番号を登録してから一時コード送信
  async onClickSendCode(event){
    this.setState({ isProcess : true });
    
    var chk_number = chkNum(this.state.TelNumber);
    
    if(chk_number === true){
      var phoneNumber = this.state.TelNumber;
      phoneNumber = phoneNumber.replace('0','+81');

      const updatePath = '/user/update';
      const userUpdateParams = {
        tell: phoneNumber,
        accessToken: this.state.accessToken,
        // phone_number_verified: true,
      };

      const assignAuthCodePath = '/mfa/assignAuthCode';
      const assignAuthCodeParams = {
        accessToken: this.state.accessToken,
        status: this.state.selectedOption,
      };

      try{
        await postData(updatePath,userUpdateParams,this.state.idToken);
        await postData(assignAuthCodePath,assignAuthCodeParams,this.state.idToken);
      }catch(e){
        console.log(e);
      }
    }
    
    // SMS認証コード送信
    this.setState({ 
      SMScode : true
    });
    console.log("onClickSendCode");
    console.log(this.state);
    this.setState({ isProcess: false });
  }
  
  //一時コード再送信
  async onClickReSendCode(event){
    // this.setState({ isProcess : true });
    
    // SMS認証コード送信
    const reSendPath = '/mfa/assignAuthCode';
    const reSendPathParams = {
      accessToken: this.state.accessToken,
      status: this.state.selectedOption,
    };

    try{
      await postData(reSendPath,reSendPathParams,this.state.idToken);
    }catch(e){
      console.log(e);
    }

    this.setState({ 
      isProcessSuccess : true,
      messageShow : true
    });
    console.log("onClickReSendCode");
    console.log(this.state);
    // this.setState({ isProcess: false });
  }

  //戻るボタン処理
  onClickSendCodeBack(event){
    this.setState({ isProcess : true });
    this.setState({ 
      SMScode : false,
      AuthCode: '',
      messageShow: ''
    });
    console.log("onClickSendCodeBack");
    console.log(this.state);
    this.setState({ isProcess: false });
  }

  onShowHideSecretKey(event){
    this.setState({ showSecretkey : !this.state.showSecretkey });
  }

  /**
     * 登録ボタン
     */
  async handleUpdate(){
  
    this.setState({ isProcess : true });
    
    const activatePath = '/mfa/activate';
    const activateParams = {
      accessToken: this.state.accessToken,
      //TOTPとSMSのどちらでMFA設定を行っているかをstate持たせても良さそう。
      status: this.state.TOTPopen ? 'TOTP' : 'SMS',
      code: this.state.AuthCode,
    };

    // var phoneNumber = this.state.TelNumber;
    // phoneNumber = phoneNumber.replace('0','+81');

    // const updatePath = '/user/update';
    // const userUpdateParams = {
    //   username: this.state.username,
    //   name: this.state.name,
    //   email: this.state.email,
    //   tell: phoneNumber,
    //   accessToken: this.state.accessToken,
      // phone_number_verified: true,
      // mfaFlag: '1',
    // };

    try{
      // await postData(updatePath,userUpdateParams,this.state.idToken);
      await postData(activatePath,activateParams,this.state.idToken);
      
      this.setState({ isProcessSuccess: true});
      window.location.href = this.state.sendUrl;
      // window.location.href = "https://user.service.sandbox.blender-melco.com/test2";
    }catch(e){
      console.log(e);
      this.setState({ 
        isProcessSuccess: false,
        messageShow : true
      });
    }

    console.log(this.state);
    
    this.setState({ isProcess: false });
  }

  /**
   * メッセージを閉じる
   */
  handleMessageClose() {
    this.setState({ messageShow : false });
  }

  render() {
    return (
      <Container>
        <Row><br/></Row>
        <Row className="justify-content-md-center">
          <Col sm="10" lg="7">
          {/* { !this.state.isProcess ?
            <Button variant="primary" className="btn-block" onClick={this.testUpdate}>セット</Button> :
            <Button variant="primary" className="btn-block" disabled>
              <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> &nbsp;処理中...
            </Button>
          }
          { !this.state.isProcess ?
            <Button variant="primary" className="btn-block" onClick={this.testHandleUpdate}>登録</Button> :
            <Button variant="primary" className="btn-block" disabled>
              <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> &nbsp;処理中...
            </Button>
          } */}
          { this.state.TOTPopen || this.state.SMSopen ? '': 
            <Card>
              <Card.Body>
                <Card.Title >多要素認証(MFA)の選択</Card.Title>
                <div>{this.state.sendUrl}</div>
                <Card.Subtitle className="mb-2 text-muted">サインインする前に、多要素認証(MFA)の設定をする必要があります。<br/>認証方法を選択してください。</Card.Subtitle>
                <form onSubmit={this.formSubmit} >
                  <div className="radio border-bottom">
                    <table><tr>
                      <td>
                      <input
                        type="radio"
                        value="SMS"
                        checked={this.state.selectedOption === "SMS"}
                        onChange={this.onValueChange}
                      />
                      </td>
                      <td width="121" height="156">
                      <Figure>
                        <Figure.Image
                          width={121}
                          height={156}
                          alt="121x156"
                          src="mfa_sms.png"
                        />
                      </Figure>
                      </td>
                      <td>
                        <h5>SMS</h5>
                        携帯電話にテキスト メッセージで送信されたコードを使用して認証します。
                      </td>
                    </tr></table>
                  </div>
                  <div className="radio">
                    <table><tr>
                      <td>
                      <input
                        type="radio"
                        value="TOTP"
                        label=""
                        checked={this.state.selectedOption === "TOTP"}
                        onChange={this.onValueChange}
                      />
                      </td>
                      <td width="121" height="156">
                      <Figure>
                        <Figure.Image
                          width={121}
                          height={156}
                          alt="121x156"
                          src="mfa_app.png"
                        />
                      </Figure>
                      </td>
                      <td>
                        <h5>認証アプリ</h5>
                        モバイル デバイスまたはコンピューターにインストールされているアプリによって生成されたコードを使用して認証します。
                      </td>
                    </tr></table>
                  </div>
                  { !this.state.isProcess ?
                    <Button variant="primary" className="btn-block" type="submit">続ける</Button>:
                    <Button variant="primary" className="btn-block" disabled>
                      <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> &nbsp;処理中...
                    </Button>
                  }
                </form>
              </Card.Body>
            </Card>
          }
        
          <Fade in={this.state.TOTPopen} style={this.state.TOTPopen ? {display:'block'} : {display:'none'}}>
            <Card>
              <Card.Body>
                <Card.Title >認証アプリ セットアップ</Card.Title>
                <Form id="TOTPregistMsg">
                  <div className="border-bottom">
                    <table><tr>
                      <td width="30" >
                      1
                      </td>
                      <td width="150" height="156">
                      <Figure>
                        <Figure.Image
                          width={121}
                          height={156}
                          alt="121x156"
                          src="mfa_app.png"
                        />
                      </Figure>
                      </td>
                      <td>
                        モバイル デバイスに認証アプリをインストールします。
                      </td>
                    </tr></table>
                  </div>
                  <div className="border-bottom">
                    <table><tr>
                      <td width="30" >
                      2
                      </td>
                      <td width="150" height="156">
                        <Form.Group controlId="formQR">
                          <QRCode value={this.state.secretCode}/>
                        </Form.Group>
                      </td>
                      <td>
                        認証アプリでこの QR コードをスキャンします。<br/>
                        または、認証アプリでシークレット キーを手動で入力することもできます。<br/><br/>
                        <Card.Link href="#" onClick={this.onShowHideSecretKey}>シークレット キーを{!this.state.showSecretkey ? "表示" : "隠す"}</Card.Link>
                      </td>
                    </tr></table>
                    <Alert show={this.state.showSecretkey} variant="dark">
                      シークレット キー<br/>
                      {this.state.secretCode}
                    </Alert>
                  </div>
                  <div >
                    <table><tr>
                      <td width="30" >
                      3
                      </td>
                      <td>
                      <br/>
                      <Form id="AuthCodeForm1">
                        <Form.Label>認証アプリからコードを入力してください。</Form.Label>
                        <Form.Control type="code" value={this.state.AuthCode} onChange={this.handleChange}/>
                      </Form>
                      </td>
                    </tr></table>
                  </div>
                </Form>
                <br/>
                { !this.state.isProcess ?
                  <Button variant="primary" className="btn-block" onClick={this.handleUpdate}>登録</Button> :
                  <Button variant="primary" className="btn-block" disabled>
                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> &nbsp;処理中...
                  </Button>
                }
                <Button variant="secondary" className="btn-block" onClick={this.formBack}>戻る</Button>
              </Card.Body>
            </Card>
          </Fade>

          <Fade in={this.state.SMSopen && !this.state.SMScode} style={this.state.SMSopen && !this.state.SMScode ? {display:'block'} : {display:'none'}}>
            <Card>
              <Card.Body>
                <Card.Title >SMS セットアップ</Card.Title>
                <Form id="AuthCodeForm2">
                  <Form.Label>電話番号を入力してください。</Form.Label>
                  <Form.Control type="tel" value={this.state.TelNumber} onChange={this.telnumberChange}/>
                </Form>
                <br/>
                { !this.state.isProcess ?
                  <Button variant="primary" className="btn-block" onClick={this.onClickSendCode}>コードを送信</Button> :
                  <Button variant="primary" className="btn-block" disabled>
                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> &nbsp;処理中...
                  </Button>
                }
                <Button variant="secondary" className="btn-block" onClick={this.formBack}>戻る</Button>
              </Card.Body>
            </Card>
          </Fade>

          <Fade in={this.state.SMSopen && this.state.SMScode} style={this.state.SMSopen && this.state.SMScode ? {display:'block'} : {display:'none'}}>
            <Card>
              <Card.Body>
                <Card.Title >SMS セットアップ</Card.Title>
                { this.state.isProcessSuccess ? 
                  <Alert show={this.state.messageShow} variant="success" onClose={this.handleMessageClose} dismissible>
                    新しい SMS コードを送信しました。
                  </Alert>
                  :
                  <Alert show={this.state.messageShow} variant="danger" onClose={this.handleMessageClose} dismissible>
                    TODO エラーメッセージ
                  </Alert>
                }
                <Form id="AuthCodeForm2">
                  <Form.Label>
                    *******{this.state.TelNumber.slice( -4 )} に SMS で認証コードを配信しました。<br/>
                    コードを入力して認証を完了してください。
                  </Form.Label>
                  <Form.Control type="code" value={this.state.AuthCode} onChange={this.handleChange}/>
                </Form>
                <br/>
                { !this.state.isProcess ?
                  <Button variant="primary" className="btn-block" onClick={this.handleUpdate}>登録</Button> :
                  <Button variant="primary" className="btn-block" disabled>
                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> &nbsp;処理中...
                  </Button>
                }
                <Button variant="secondary" className="btn-block" onClick={this.onClickSendCodeBack}>戻る</Button>
                <br/>
                コードが届かない場合は、<Card.Link href="#" onClick={this.onClickReSendCode}>新しいコードを送信</Card.Link>
              </Card.Body>
            </Card>
          </Fade>
          </Col>
        </Row>
      </Container>
    )
  }
}
export default MfaSetting;

async function postData(url,param,idToken) { 
  const apiName = 'api_url_base';
  const path = url;
  const myInit = { 
      method : 'POST',
      body: param, 
      headers: {
        'Content-type' : 'application/json',
        Authorization: idToken,
      }
  };

  return await API.post(apiName, path, myInit);
}

function chkNum(obj){
  var num="0123456789";
  var tmp=[];
  for(var i=0; i<obj.length; i++){
      tmp[i]=obj.substring(i,i+1);
      var flag=num.indexOf(tmp[i]);
      if(flag===-1){
          console.log("数値(0-9)以外が含まれています");
          return false;
      }
  }
  return true;
}
