import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import MfaSetting from './views/MfaSetting';
// import PasswdSetting from './views/PasswdSetting';
// import ProfileSetting from './views/ProfileSetting';
import AppNavBar from './views/AppNavBar';
import './App.css';
import {Amplify, Auth, Hub } from 'aws-amplify';
import awsconfig from './amplifyConfig';
import { Card, Form } from 'react-bootstrap';

Amplify.configure(awsconfig);
  
  function App() {
    const [user, setUser] = useState(null);
    const location = window.location.pathname;
    
    useEffect(() => {
      
      Hub.listen('auth', ({ payload: { event, data } }) => {
        switch (event) {
          case 'oAuthSignOut':
            console.log('ログアウト');
            break;
          case 'signIn_failure':
            case 'cognitoHostedUI_failure':
              console.log('Sign in failure', data);
              break;
          default : console.log('ログインしていません。');
        }
      });

      if(location !== '/logOut'){
        getUser().then(userData => setUser(userData));
      }
    }, []);
  
    function getUser() {
      const urlParams = new URLSearchParams(window.location.search);
      console.log(urlParams);
      for (let p of urlParams) {
        if (p[0] === "url") {
          var Uri = p[1];
          console.log(Uri);
          localStorage.setItem("uri",Uri);
          break;
        }
      }

      return Auth.currentAuthenticatedUser()
        .then(userData => userData)
        .catch(() => Auth.federatedSignIn());
    }
  
    return (
      <div className="back">
      {location === '/logOut' ? (
          <Card>
          <Card.Body>
              <Form id="infoMsg">
                  <Form.Label column sm={8}>
                  ログアウトしました。<br/>完全にログアウト処理が完了するまで少しかかります。
                  </Form.Label>
              </Form>
          </Card.Body>
      </Card>
      ) :user ? (
              <Router>
                <AppNavBar />
                <Routes>
                  <Route exact path='/' element={<MfaSetting />} />
                  {/* <Route exact path='/passwd' component={PasswdSetting}/> */}
                  {/* <Route path='/profile' element={<ProfileSetting />}/> */}
                </Routes>
            </Router>
       ) : (
        ''
      ) } 
      </div>
    );
  }

export default App;
